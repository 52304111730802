
export const getUserTokenCallback = async (user, forceRefresh = false, callback) => {
  if (!user) return
  const token = await user.getIdToken(forceRefresh)

  if (callback) {
    const output = await Promise.resolve(callback(token))
    return { output, token }
  }

  return token
}

export const getUserInfoFromTokenBase = async (firebase, token) =>
  await firebase.auth().verifyIdToken(token)

export const getUserFromEmailBase = async (firebase, email) =>
  await firebase.auth().getUserByEmail(email)

export const getUserBase = async (firebase, uid) =>
  await firebase.auth().getUser(uid)

export const getSessionCookieBase = async (firebase, token, { expiresIn }) =>
  await firebase.auth().createSessionCookie(token, {
    expiresIn,
  })

export const checkSessionCookieBase = async (firebase, sessionCookie, checkRevoked = false) =>
  await firebase.auth().verifySessionCookie(sessionCookie, checkRevoked)

export const getItemsAllEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  options = {}
) => {
  const { rawData = false, includeKey = false, logItem = true } = options

  if (!siteId || !entity || !area) return

  const refBase = `/app/${siteId}/${area}/${entity}`

  const dbRef = firebase.database().ref(refBase)

  const data = await dbRef.once("value")
  const data_val = data.val()

  if (rawData) return data_val

  const values = data_val ? Object.values(data_val) : data_val

  console.debug(
    "🚀 ~ file: firebaseDataUtils.js:54 ~ getItemsAllEntity:",
    refBase,
    values?.length
  )
  if(logItem) console.debug(values?.[0])

  return data_val && includeKey
    ? Object.entries(data_val).map(([key, val]) => ({ ...val, key }))
    : values
}

export const getDataByFullPathBase = async (firebase, fullPath) => {
  console.debug("🚀 ~ file: firebaseBaseDataUtils.js:45 ~ getDataByFullPathBase ~ fullPath:", fullPath)
  if (!fullPath) return

  const refBase = fullPath

  const dbRef = firebase.database().ref(refBase)

  const data = await dbRef.once("value")
  const data_val = data.val()

  return data_val

}

export const getItemEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  key,
  options = {}
) => {
  const { includeKey = false, logItem = true } = options

  if (!siteId || !entity || !area || !key) return

  const refBase = `/app/${siteId}/${area}/${entity}/${key}`

  const dbRef = firebase.database().ref(refBase)
  
  const data = await dbRef.once("value")
  const val = data.val()
  
  console.debug("🚀 ~ file: firebaseBaseDataUtils.js:86 ~ getItemEntityBase ~ refBase:", refBase)
  if (logItem) console.debug(val)
  return val && includeKey ? { ...val, key } : val
}

export const deleteItemEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  key
) => {
  if (!siteId || !entity || !area || !key) return false

  const refBase = `/app/${siteId}/${area}/${entity}/${key}`

  const dbRef = firebase.database().ref(refBase)

  await dbRef.remove()

  console.debug(
    "🚀 ~ file: firebaseDataUtils.js:38 ~ deleteItemEntity ~ deleteItemEntity:",
    refBase
  )
  return true
}

export const updateItemEntityBase = async (
  firebase,
  site,
  entity,
  area,
  key,
  datos,
  options = {}
) => {
  const { includeKey = false, getItem = false, logItem = true } = options

  if (!site || !entity || !area || !key || !datos) return

  const refBase = `/app/${site}/${area}/${entity}/${key}`

  const dbRef = firebase.database().ref(refBase)

  await dbRef.update(datos)

  console.debug(
    "🚀 ~ file: firebaseDataUtils.js:71 ~ updateItemEntity:",
    refBase
  )
  if(logItem) console.debug(datos)
  if (getItem) return await getItemEntityBase(firebase, site, entity, area, key, options)

  return includeKey ? { ...datos, key } : datos
}

export const addItemEntityBase = async (
  firebase,
  site,
  entity,
  area,
  key,
  datos,
  options = {}
) => {
  const { includeKey = false, getItem = false, logItem = true } = options

  if (!site || !entity || !area || !datos) return

  const tieneKey = !!key
  let keyNew = key

  const refBase = tieneKey
    ? `/app/${site}/${area}/${entity}/${key}`
    : `/app/${site}/${area}/${entity}/`

  const dbRef = firebase.database().ref(refBase)

  if (tieneKey) {
    await dbRef.set(datos)
  } else {
    const data = await dbRef.push(datos)
    keyNew = data.getKey()
  }
  
  if (logItem) 
    console.debug("🚀 ~ file: firebaseDataUtils.js:111 ~ addItemEntity:",refBase,datos)

  if (getItem) return await getItemEntityBase(firebase, site, entity, area, keyNew, options)

  return includeKey ? { ...datos, key: keyNew } : datos
}
