import React, { createContext, useState, useEffect, useCallback } from "react"

import { getUserTokenCallback } from "acciondigital-commons/firebaseBaseDataUtils"
import firebase from "acciondigital-basic-theme/src/utils/get-firebase-instance"

import { usuariosControl_area, usuarios_area } from "../utils/usuarios"

export const AuthContext = createContext()

const AuthProvider = ({ sitio, children }) => {
  const [user, setUser] = useState()
  const [infoUsuarios, setInfoUsuarios] = useState(undefined)
  const [infoUsuariosControl, setInfoUsuariosControl] = useState(undefined)
  const [infoControl, setInfoControl] = useState(undefined)

  // console.log("AuthProvider sitio", { sitio })

  const getUserToken = useCallback(
    async (forceRefresh = false, callback) => await getUserTokenCallback(user, forceRefresh, callback),
    [user]
  )
  

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(usuario => {
      console.debug("AuthProvider onAuthStateChanged", {
        user: usuario,
        email: usuario?.email,
      })

      if (usuario?.uid) {
        setUser(usuario)
      } else {
        setUser(undefined)
        setInfoUsuarios(undefined)
        setInfoUsuariosControl(undefined)
        setInfoControl(undefined)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  //infoUsuarios
  useEffect(() => {
    const user_uid = user?.uid
    const refBase = `/app/${sitio}/${usuarios_area}/${user_uid}`
    const ref = firebase.database().ref(refBase)
    console.debug("AuthProvider useEffect usuarios", refBase)

    if (user_uid && sitio) {
      const listener = snapshot => {
        const datos = snapshot.val()
        // console.log({datos})
        if (datos) setInfoUsuarios(datos)
        else {
          setInfoUsuarios(undefined)
        }
      }
      ref.on("value", listener)
      return () => ref.off("value", listener)
    } else {
      setInfoUsuarios(undefined)
    }
  }, [user, sitio])

  //infoUsuariosControl
  useEffect(() => {
    const user_uid = user?.uid
    const refBase = `/app/${sitio}/${usuariosControl_area}/${user_uid}`
    const ref = firebase.database().ref(refBase)
    console.debug("AuthProvider useEffect usuarios-control", refBase)

    if (user_uid && sitio) {
      const listener = snapshot => {
        const datos = snapshot.val()
        // console.log({datos})
        if (datos) setInfoUsuariosControl(datos)
        else {
          setInfoUsuariosControl(undefined)
        }
      }
      ref.on("value", listener)
      return () => ref.off("value", listener)
    } else {
      setInfoUsuariosControl(undefined)
    }
  }, [user, sitio])

  //infoControl
  useEffect(() => {
    const user_uid = user?.uid
    const refBase = `/app/${sitio}/control/${user_uid}`
    const ref = firebase.database().ref(refBase)
    console.debug("AuthProvider useEffect control", refBase)

    if (user_uid && sitio) {
      // console.log("🚀 ~ file: AuthContext.js ~ line 73 ~ useEffect ~ user_uid", user_uid)
      const listener = snapshot => {
        const datos = snapshot.val()
        // console.log({ control: datos })
        if (datos) setInfoControl(datos)
        else {
          setInfoControl(undefined)
        }
      }
      ref.on("value", listener)
      return () => ref.off("value", listener)
    } else {
      setInfoControl(undefined)
    }
  }, [user, sitio])

  return (
    <AuthContext.Provider
      value={{
        user,
        infoUsuarios,
        infoUsuariosControl,
        infoControl,
        getUserToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
