export function getStorageValue(key, defaultValue) {
    // getting stored value
    if (typeof window !== "undefined") {
      const saved = localStorage.getItem(key)
      const initial =
        saved === "undefined"
          ? undefined
          : saved !== null
          ? JSON.parse(saved)
          : defaultValue
      return initial
    }
  }