import React, {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react"
// import moment from "moment"

import {
  cartInfoDefault,
  calcularCartOper,
} from "acciondigital-commons/cartCommonsUtils"
import { ACTIONS, getReducer } from "acciondigital-commons/cartReducer"

import { AuthContext } from "../context/AuthContext"
import useCartOpers from "../hooks/useCartOpers"
import { guardarPedido } from "../utils/db/firebase/pedidos"
import {
  iniCartInfo,
} from "../utils/cartUtils"

// const reducer = getReducer(iniCartInfo)




const CartContext = createContext()


// export const preparaInfoCartCarga = preparaInfoCartCargaOper

// export const calcularCart = calcularCartOper

// export const calcularCartFromDB = calcularCartFromDBOper

export const crearCartProvider = (reglas) => {  
  const reducer = getReducer(iniCartInfo, reglas)

return props => {
  const { sitio, saveToFirebase, vigenciaDiasDefault } = props
  
  const [cartInfoState, dispatch] = useReducer(
    reducer,
    { ...cartInfoDefault, sitio, vigenciaDiasDefault },
    info => {
      const state = iniCartInfo(info)
      return calcularCartOper(state, state.items, null, state.control)
    }
  )

  const preventSaveFirebaseRef = useRef(true)

  const { user } = useContext(AuthContext) ?? {}


  const cartOpers = useCartOpers(cartInfoState, dispatch, user, preventSaveFirebaseRef, {sitio, vigenciaDiasDefault})

  useEffect(() => {
    preventSaveFirebaseRef.current = true
    const user_uuid = user?.uid ? user.uid : ""
    console.debug("CartContext useEffect p1 user_uuid", user_uuid)
    dispatch({
      type: ACTIONS.CARGAR_NUEVO,
      payload: { user_uuid, sitio, vigenciaDiasDefault },
    })
    // preventSaveFirebaseRef.current = true
  }, [user, sitio, vigenciaDiasDefault])

  useEffect(() => {
    const saveFirebase = async () => {
      if (!preventSaveFirebaseRef.current) {
        try {

          const resultado = await guardarPedido(
            sitio,
            cartInfoState.uuid,
            cartInfoState["user-uid"],
            cartInfoState
          )

          console.debug("CartContext useEffect p2 saveFirebase SUCCESS", { resultado })
        } catch (error) {
          console.error("CartContext saveFirebase ERROR", error.message)
        }
      } else {
        console.debug(
          "CartContext useEffect p2 saveFirebase preventSaveFirebaseRef",
          preventSaveFirebaseRef.current
        )
        preventSaveFirebaseRef.current = false
      }
    }

    const user_uuid = cartInfoState["user-uid"]
    const cart_key = `currentCart_${sitio}${user_uuid ? "_" + user_uuid : ""}`
    localStorage.setItem(cart_key, JSON.stringify(cartInfoState))
    console.debug(`CartContext useEffect p2 setItem ${cart_key}`, cartInfoState)
    // if (saveToFirebase && cartInfoState?.items?.length > 0)
    if (saveToFirebase) saveFirebase()
  }, [cartInfoState, sitio, saveToFirebase])

  return (
    <CartContext.Provider
      value={{
        cartId: cartInfoState.uuid,
        items: cartInfoState.items,
        total: cartInfoState.total,
        cantArticulos: cartInfoState.cantArticulos,
        cantLineas: cartInfoState.cantLineas,
        cartInfoExtra: cartInfoState.cartInfoExtra,
        cartState: cartInfoState,
        cartInfo: cartInfoState,
        control: cartInfoState.control,
        estatus: cartInfoState.estatus,
        vigente: cartInfoState.vigente,
        cerrado: cartInfoState.cerrado,
        ...cartOpers

      }}
      {...props}
    />
  )
}

}

const CardProvider = crearCartProvider()

export default CartContext

CardProvider.defaultProps = {
  sitio: "AccionDigital",
  saveToFirebase: false,
  vigenciaDiasDefault: 3,
}
export { CardProvider }

export { CardProvider as CartProvider }
