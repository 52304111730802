
import {
  pedidos_entity as pedidos_entity_,
  pedidos_area as pedidos_area_,
  pedidos_usuariosEntity as pedidos_usuariosEntity_,
  pedidos_entityUsuarios as pedidos_entityUsuarios_,
  guardarPedidoBase,
  savePedidoUsuarioBase,
  fixPedidos_CrearRegistrosUsuariosBase,
  prepararEliminarPedidoBase,
  cancelarEliminarPedidoBase,
  confirmarEliminarPedidoBase,
} from "acciondigital-commons/pedidos-commons"

import firebase from "acciondigital-basic-theme/src/utils/get-firebase-instance"

import { fixPedidoCrearVenta } from "./ventas"

export const pedidos_entity = pedidos_entity_
export const pedidos_area = pedidos_area_
// export const pedidos_usuariosEntity = "usuarios-entity"
// export const pedidos_entityUsuarios = "entity-usuarios"
export const pedidos_usuariosEntity = pedidos_usuariosEntity_
export const pedidos_entityUsuarios = pedidos_entityUsuarios_


export const savePedidoUsuario = async (sitio, uuid, user_uuid, cartInfo) => await savePedidoUsuarioBase(firebase, sitio, uuid, user_uuid, cartInfo)

export const guardarPedido = async (sitio, uuid, user_uuid, cartInfo) => await guardarPedidoBase(firebase, sitio, uuid, user_uuid, cartInfo)

export const fixPedidos_CrearRegistrosUsuarios = async (siteId, pedidosLst) => await fixPedidos_CrearRegistrosUsuariosBase(firebase, siteId, pedidosLst)


/*
    creando ventas que no se crearon de cotizaciones marcadas como ventas
 */
    export const fixPedidos_CrearVentas = async (siteId, pedidosLst) => {
      let count = 0
      for (let index = 0; index < pedidosLst.length; index++) {
        const p = pedidosLst[index]
        const {
          uuid,
          esVenta,
          "venta-uid": venta_uid
        } = p
        
        if (esVenta && !venta_uid) {
          await delay(800)
          console.log("🚀 ~ file: AdmonPedidosPageComp.js:136 ~ fixVentas ~ p:", p)
          
          const venta_uid = await fixPedidoCrearVenta(siteId, p, p?.fechaVentaInt)
          // await savePedidoUsuario(siteId, uuid, user_uid, p)
          // if(user_uid != user_creador_uid) 
          //   await savePedidoUsuario(siteId, uuid, user_creador_uid, p)
          count++
        }
        console.log("🚀 ~ file: AdmonPedidosPageComp.js:148 ~ fixVentas ~ index:", {
          index,
          count,
          uuid,
        })
        // if (count == 25) break
        // if (count == 50) break
        if (count == 25) break
        // if (count == 10) break
        // if (count == 1) break
      }
    }

    


    export const prepararEliminarPedido = async (siteId, id) => await prepararEliminarPedidoBase(firebase, siteId, id)

    export const cancelarEliminarPedido = async (siteId, id) => await cancelarEliminarPedidoBase(firebase, siteId, id)

    export const confirmarEliminarPedido = async (siteId, id) => await confirmarEliminarPedidoBase(firebase, siteId, id)