import axios from "axios"

import {
  usuarios_area as usuarios_area_,
  usuariosControl_area as usuariosControl_area_,
  GET_USERS_FIREBASE_API as GET_USERS_FIREBASE_API_,
  saveUserInfoBase,
  saveUserInfoFromUserBase,
} from "acciondigital-commons/usuarios-commons"

import firebase from "./get-firebase-instance"

export const usuarios_area = usuarios_area_
export const usuariosControl_area = usuariosControl_area_

export const GET_USERS_FIREBASE_API = GET_USERS_FIREBASE_API_

export const usersFirebaseClient = axios.create({
  baseURL: GET_USERS_FIREBASE_API,
  headers: {
    "Content-type": "application/json",
  },
})

export const saveUserInfo = async (
  refSitio,
  user,
  newDatos,
  fechaDate = new Date()
) =>
  await saveUserInfoBase(
    firebase,
    refSitio,
    user,
    newDatos,
    fechaDate
  )

export const saveUserInfoFromUser = async (refSitio, user) =>
  await saveUserInfoFromUserBase(firebase, refSitio, user)
