import moment from "moment"

import {
  getFechaCreacionObj as getFechaCreacionObj_,
  getFechaCreacionActualizacionObj as getFechaCreacionActualizacionObj_,
  getFechaActualizacionObj as getFechaActualizacionObj_,
  getFechaObj as getFechaObj_,
} from "acciondigital-commons/commonUtils"

export function getBasicSortDesc(field, nested) {
  return (a, b) => {
    const aVal = !a ? a : 'function' == typeof field ? field(a) : nested ? getNestedProperty(field, a) : a[field];
    const bVal = !b ? b : 'function' == typeof field ? field(b) : nested ? getNestedProperty(field, b) : b[field];
    return bVal == null
      ? -1
      : aVal == null
      ? 1
      : bVal > aVal
      ? 1
      : bVal < aVal
      ? -1
      : 0
  }
}

export function getBasicSortAsc(field, nested) {
  return (b, a) => {
    const aVal = !a ? a : 'function' == typeof field ? field(a) : nested ? getNestedProperty(field, a) : a[field]
    const bVal = !b ? b : 'function' == typeof field ? field(b) : nested ? getNestedProperty(field, b) : b[field]
    return aVal == null
      ? -1
      : bVal == null
      ? 1
      : bVal > aVal
      ? 1
      : bVal < aVal
      ? -1
      : 0
  }
}

export function getRandomItemsUnicos(lista = [], cant) {
  const resultado = [];
  
  // Validar que el segundo argumento sea un número entero positivo
  if (!Number.isInteger(cant) || cant <= 0) {
    throw new Error('La cantidad debe ser un número entero positivo');
  }

  if(cant > lista.length) return lista
  
  // Obtener n índices aleatorios únicos del array
  const indicesAleatoriosUnicos = new Set();
  while (indicesAleatoriosUnicos.size < cant) {
    const indiceAleatorio = Math.floor(Math.random() * lista.length);
    indicesAleatoriosUnicos.add(indiceAleatorio);
  }
  
  // Recuperar los elementos correspondientes a los índices aleatorios del array
  for (const indice of indicesAleatoriosUnicos) {
    resultado.push(lista[indice]);
  }
  
  return resultado;
}

export function getFichaTruperUrl(codigo) {
  return `https://www.truper.com/ficha_tecnica/controllers/index.php?codigo=${codigo}`
};

export function getTruperThumbnailImageByCodigo(codigo) {
  return codigo
    ? `https://www.truper.com/admin/images/ch/${codigo}.jpg`
    : "https://i.postimg.cc/1RDsgtKZ/image.png"
}

export function getTruperImageByClave(clave) {
  return clave
    ? `https://www.truper.com/media/import/imagenes/${clave
        .trim()
        .toUpperCase()
        .split("Ñ")
        .join("N")
        .split("/")
        .join("-")
        .split(" ")
        .join("-")}.jpg`
    : "https://i.postimg.cc/1RDsgtKZ/image.png"
}

export function getNestedProperty(path, obj, separator = ".") {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev?.[curr], obj)
}

export function delay(t = 1000) {
  return new Promise(resolve => setTimeout(resolve, t))
}

export function fixUrl(url, siteUrl) {
  return (url.indexOf("http") >= 0 ? "" : siteUrl) + url
}

export const removeAttributes = (itemInfo, encabezados) => {
  const obj = { ...itemInfo }
  encabezados.forEach(key => {
    delete obj[key]
  })
  return obj
}

export const getAttributes = (
  itemInfo,
  encabezados,
  nullEmptyString = true,
  includeNulls = true
) => {
  if (!itemInfo) return
  if (!encabezados) return {}

  return encabezados.reduce((obj, key) => {
    if (itemInfo[key] || includeNulls)
      obj[key] = itemInfo[key] ?? (nullEmptyString ? "" : undefined)

    return obj
  }, {})
}

export const getFechaCreacionObj = getFechaCreacionObj_

export const getFechaCreacionActualizacionObj = getFechaCreacionActualizacionObj_

export const getFechaActualizacionObj = getFechaActualizacionObj_

export const getFechaObj = getFechaObj_

export const getAntiguedad = (fecha, opciones = {}) => {
  const { hoy, formatoCorto = false } = opciones

  const fecha_moment = moment(fecha)

  const fecha_hoy = hoy ? moment(hoy) : moment()

  const anios = fecha_hoy.diff(fecha_moment, "years")
  const meses_totales = fecha_hoy.diff(fecha_moment, "months")
  const fecha_mas_anios = moment(fecha).add(anios, "years")
  const meses = fecha_hoy.diff(fecha_mas_anios, "months")
  const fecha_mas_meses = moment(fecha).add(meses_totales, "months")
  const dias = fecha_hoy.diff(fecha_mas_meses, "days")

  // return `${anios} años, ${meses_totales>=12?meses:meses_totales} meses, ${dias} días`
  return formatoCorto
    ? `${anios}a  ${meses}m ${dias}d`
    : `${anios} años, ${meses} meses, ${dias} días`
}

export const getDescripcion = (descCorta, descLarga, caracteres = 150) => {
  const descStr = descCorta ?? descLarga ?? ""
  return (
    descStr.substring(0, caracteres) +
    (descStr.length > caracteres ? " . . ." : "")
  )
}

export const precioMoneda = str => {
  if (!str) return
  return str.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
  })
}

export const numberFormat = value =>
  value != undefined
    ? new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(value)
    : value

export const firstAttributes = (item, cant = 5) => {
  if (!item) return

  return Object.entries(item).reduce((obj, [key, value], idx) => {
    if (idx < cant) obj[key] = value
    return obj
  }, {})
}

export const capitalizeFirstLetter = string => {
  if (!string) return
  return string.charAt(0).toUpperCase() + string.slice(1)
}
