import { v4 as uuidv4 } from "uuid"

import {
  ventas_entity as ventas_entity_,
  ventas_area as ventas_area_,
  ventas_usuariosEntity as ventas_usuariosEntity_,
  ventas_entityUsuarios as ventas_entityUsuarios_,
} from "acciondigital-commons/ventas-commons.js"

import firebase from "acciondigital-basic-theme/src/utils/get-firebase-instance"
import {
  getAttributes,
  getFechaCreacionActualizacionObj,
  numberFormat,
} from "acciondigital-basic-theme/src/utils/commonUtils"
import {
  addItemEntity,
  getItemsAllEntity,
  updateItemEntity,
} from "acciondigital-basic-theme/src/utils/db/firebase/firebaseDataUtils"

import { pedidos_area, pedidos_entity } from "./pedidos"
import { getFechaTicket } from "acciondigital-commons/cartCommonsUtils"

// const siteId = ""
// entity,
// area,
// key,
// datos,
// options = {}

export const ventas_entity = ventas_entity_
export const ventas_area = ventas_area_
export const ventas_usuariosEntity = ventas_usuariosEntity_
export const ventas_entityUsuarios = ventas_entityUsuarios_

const getUserVentaInfo = ventaInfo => {
  const info = getAttributes(
    ventaInfo,
    [
      "uuid",
      "total",
      "cantArticulos",
      "fechaCreacionInt",
      "fechaActualizacionInt",
    ],
    false,
    false
  )

  // if (ventaInfo["user-creador-uid"])
  //   info["user-creador-uid"] = ventaInfo["user-creador-uid"]

  return info
}

export const saveVentaUsuario = async (sitio, uuid, user_uuid, ventaInfo) => {
  if (user_uuid) {
    addItemEntity(
      sitio,
      ventas_entity,
      ventas_entityUsuarios,
      `${uuid}/${user_uuid}`,
      {
        acceso: true,
      }
    )
  }

  const vals = await getItemsAllEntity(
    sitio,
    ventas_entity + "/" + uuid,
    ventas_entityUsuarios,
    { includeKey: true }
  )
  console.debug("🚀 ~ file: ventas.js:52 ~ saveVentaUsuario ~ vals:", vals)

  const userVentaInfo = getUserVentaInfo(ventaInfo)

  const updates = {}
  if (vals?.[0]) {
    vals.forEach(v => {
      updates[
        `/app/${sitio}/${ventas_usuariosEntity}/${ventas_entity}/${v.key}/${uuid}/`
      ] = {
        ...userVentaInfo,
        "user-uid": v.key + "",
      }
    })
    console.debug(
      "🚀 ~ file: ventas.js:62 ~ saveVentaUsuario ~ updates:",
      updates
    )
    firebase.database().ref().update(updates)
  }
}

export const fixPedidoCrearVenta = async (siteId, cartInfo, fechaInt) => {
  if (!cartInfo?.esVenta || !cartInfo?.cantArticulos) return

  const {
    uuid,
    cantArticulos,
    cartInfoExtra,
    items,
    total,
    "user-info": user_info,
    "user-uid": user_uid,
    uuid: pedido_uuid,
  } = cartInfo
  // console.debug("🚀 ~ file: ventas.js:27 ~ crearVenta ~ cartInfo:", cartInfo)

  const fechaObj = getFechaCreacionActualizacionObj(
    fechaInt ? new Date(fechaInt) : undefined
  )

  const key = uuidv4()

  const ventaInfo = {
    uuid: key,
    cantArticulos,
    infoExtra: cartInfoExtra,
    items,
    total,
    "user-info": user_info,
    "user-uid": user_uid,
    "pedido-uuid": pedido_uuid,
    ...fechaObj,
  }

  //   user-priv-data
  const resVenta = await addItemEntity(
    siteId,
    ventas_entity,
    ventas_area,
    key,
    ventaInfo,
    { includeKey: true }
  )

  // const resVenta = {key}

  if (resVenta?.key) {
    cartInfoExtra["venta-uid"] = key
    const control = {
      cerrado: true,
      estatus: "VENTA",
      vigente: false,
    }
    const newCartInfo = {
      ...cartInfo,
      cartInfoExtra,
      ...cartInfoExtra,
      control,
      ...control,
      key: null,
    }
    //updatePedidoInfo
    console.debug(
      "🚀 ~ file: ventas.js:132 ~ fixPedidoCrearVenta ~ newCartInfo:",
      newCartInfo
    )
    const resUpdate = await updateItemEntity(
      siteId,
      pedidos_entity,
      pedidos_area,
      uuid,
      newCartInfo,
      { includeKey: true }
    )
    console.debug(
      "🚀 ~ file: ventas.js:161 ~ fixPedidoCrearVenta ~ resUpdate:",
      resUpdate
    )

    // cartContext.cerrarCart("VENTA", { esVenta: true, "venta-uid": key })

    await saveVentaUsuario(siteId, key, user_uid, resVenta)
    return key
  }
}

export const crearVenta = async (siteId, cartContext, opciones = {}) => {
  const { cartState: cartInfo, setCartInfoExtra } = cartContext
  const { getVenta = false} = opciones

  const pagoCon = opciones?.pagoCon || cartInfo.total
  const cambio = pagoCon - cartInfo.total

  if (cartInfo?.esVenta || !cartInfo?.cantArticulos || cartInfo.cerrado) return

  const {
    cantArticulos,
    cartInfoExtra,
    items,
    total,
    "user-info": user_info,
    "user-uid": user_uid,
    uuid: pedido_uuid,
  } = cartInfo
  console.debug("🚀 ~ file: ventas.js:27 ~ crearVenta ~ cartInfo:", cartInfo)

  const fechaObj = getFechaCreacionActualizacionObj()

  const key = uuidv4()

  const ventaInfo = {
    uuid: key,
    cantArticulos,
    infoExtra: cartInfoExtra,
    items,
    total,
    pagoCon,
    cambio,
    "user-info": user_info,
    "user-uid": user_uid,
    "pedido-uuid": pedido_uuid,
    ...fechaObj,
  }

  //   user-priv-data
  const resVenta = await addItemEntity(
    siteId,
    ventas_entity,
    ventas_area,
    key,
    ventaInfo,
    { includeKey: true }
  )
  console.debug("🚀 ~ file: ventas.js:22 ~ crearVenta ~ res:", resVenta)

  if (resVenta?.key) {
    cartContext.cerrarCart("VENTA", { esVenta: true, "venta-uid": key })

    await saveVentaUsuario(siteId, key, user_uid, resVenta)
    return getVenta ? resVenta : key
  }
}

export const cambiarFechaVenta = async (siteId, uuid, fechaObj) => {
  if (!uuid || !fechaObj) return

  //   user-priv-data
  const resVenta = await updateItemEntity(
    siteId,
    ventas_entity,
    ventas_area,
    uuid,
    { ...fechaObj },
    { includeKey: true, getItem: true }
  )

  // console.debug("🚀 ~ file: ventas.js:22 ~ crearVenta ~ res:", resVenta)

  if (resVenta?.uuid) {
    console.debug("🚀 ~ file: ventas.js:241 ~ cambiarFechaVenta ~ resVenta:", resVenta)

    await saveVentaUsuario(siteId, uuid, resVenta?.["user-uid"], resVenta)

  }
}

const getItemLineStr = item => {
  return getItemLineStrOpciones(item, false)
}

const getItemLineStrOpciones = (item, ocultarPrecios = false) => {
  const precios = !ocultarPrecios
    ? `, (${numberFormat(item.precio)} / ${numberFormat(item.importe)})`
    : ""
  return `►${item.cantidad} x ${item.descripcion} [${item.codigo}]` + precios
}

export const getVentaHtml = (ventaInfo, opciones = {}) => {
  const { } = opciones
  const { infoExtra } = ventaInfo
  let html = `
  ${ventaInfo.items.reduce((str, item) => {
    return str + `<p className="m-1">${getItemLineStr(item)}<p/>`
  }, "")}
  <br />
  <br />
  <strong>TOTAL: ${numberFormat(ventaInfo.total)}</strong>
  <br />
  <em>(${ventaInfo.cantArticulos} artículo${ventaInfo.cantArticulos === 1 ? "" : "s"})</em>
  <br />
  <br />      
  <p style={{ fontSize: ".75rem" }} className="font-italic">
    ${ventaInfo.uuid}
  </p>
  ${infoExtra?.tienda ||
      infoExtra?.telefono ||
      infoExtra?.siteUrl
      ? `<div>
      <p> ++++++++++++ </p>
      <p className="mb-1">${infoExtra?.tienda?.toLocaleUpperCase()}</p>
      <p className="mb-1"> ${infoExtra?.telefono} </p>
      <p className="mb-1">${infoExtra?.siteUrl}</p>
    </div>`
      : ""
    }
  `
  return html
}


/*
Calle, Numero exterior, Nun Interior, Colonia, Ciudad o Localidad, Municipio, Estado y Código Postal
*/

const getDireccion = infoExtra => 
  (infoExtra?.direccionFiscal?.calle ? infoExtra?.direccionFiscal?.calle : "") +
  (infoExtra?.direccionFiscal?.numExterior ? (", " + infoExtra?.direccionFiscal?.numExterior) : "") +
  (infoExtra?.direccionFiscal?.numInterior ? (", " + infoExtra?.direccionFiscal?.numInterior) : "") +
  (infoExtra?.direccionFiscal?.colonia ? (", " + infoExtra?.direccionFiscal?.colonia) : "") +
  (infoExtra?.direccionFiscal?.ciudad ? (", " + infoExtra?.direccionFiscal?.ciudad) : "") +
  (infoExtra?.direccionFiscal?.municipio ? (", " + infoExtra?.direccionFiscal?.municipio) : "") +
  (infoExtra?.direccionFiscal?.estado ? (", " + infoExtra?.direccionFiscal?.estado) : "") +
  (infoExtra?.direccionFiscal?.codigoPostal ? (", " + infoExtra?.direccionFiscal?.codigoPostal) : "")


// `${ventaInfo?.direccionFiscal?.calle || ""} ${ventaInfo?.direccionFiscal?.numExterior || ""} ${ventaInfo?.direccionFiscal?.numInterior || ""} ${ventaInfo?.direccionFiscal?.colonia || ""} ${ventaInfo?.direccionFiscal?.ciudad || ""} ${ventaInfo?.direccionFiscal?.municipio || ""} ${ventaInfo?.direccionFiscal?.estado || ""} ${ventaInfo?.direccionFiscal?.codigoPostal || ""}`

export const getTicketVentaFiscalHtml = (ventaInfo, opciones = {}) => {
  const { } = opciones
  const { infoExtra } = ventaInfo
  let html = `
  <h3 class="mb-1 text-center"  style="text-align: center;">${infoExtra?.tienda?.toLocaleUpperCase()}</h3>
  ${infoExtra?.rfc ? `<p class="mb-1 text-center"  style="text-align: center;">RFC: ${infoExtra.rfc.toLocaleUpperCase()}</p>` : ""}
  ${infoExtra?.razonSocial ? `<p class="mb-1 text-center"  style="text-align: center;"><strong>${infoExtra.razonSocial.toLocaleUpperCase()}</strong></p>` : ""}
  ${infoExtra?.regimenFiscal ? `<p class="mb-1 text-center"  style="text-align: center;">Regimen Fiscal: ${infoExtra.regimenFiscal.toLocaleUpperCase()}</p>` : ""}
  <p class="mb-1 text-center"  style="text-align: center;">${getDireccion(infoExtra)}</p>
  ${infoExtra?.telefono && infoExtra?.telefonoFijo ?
      `<p class="mb-1 text-center"  style="text-align: center;">TELS: ${infoExtra.telefono}, ${infoExtra.telefonoFijo}</p>` :
      infoExtra?.telefono || infoExtra?.telefonoFijo ?
        `<p class="mb-1 text-center"  style="text-align: center;">TELS: ${infoExtra?.telefono ?? infoExtra?.telefonoFijo}</p>` :
        ""
  }
  <p class="text-center"  style="text-align: center;">${getFechaTicket( ventaInfo.fechaCreacionInt )}</p>
  <div class="d-flex justify-content-center" style="display: flex; justify-content: flex-center;">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">CAN.</th>
        <th scope="col">CODIGO</th>
        <th scope="col">PRECIO</th>
        <th scope="col">IMPORTE</th>
      </tr>
    </thead>
    <tbody>
  ${ventaInfo?.items ? ventaInfo.items.reduce((str, item) => {
    return str + `
  <tr>
    <td colspan="4" class="border-bottom-0 pb-0">${item.descripcion}</td>
  </tr>
  <tr>
    <td>${item.cantidad}</td>
    <td>${item.codigo}</td>
    <td>${item.precio}</td>
    <td>${item.importe}</td>
  </tr>`
  }, "") : ""}
    </tbody>
    <tfoot>
    ${infoExtra?.formaPago ? 
      `<tr>
        <td colspan="2">Metodo de pago</td>
        <td colspan="2">${infoExtra.formaPago}</td>
      </tr>`:
      ""}
    </tfoot>
  </table>
  </div>
  <br/>
  <div class="d-flex justify-content-end" style="display: flex; justify-content: flex-end;">
  <table class="table w-auto">
    <tbody>
      <tr>
        <td colspan="">Su pago:</td>
        <td>${ventaInfo.pagoCon}</td>
      </tr>
      <tr>
        <td colspan="">Cambio:</td>
        <td>${ventaInfo.cambio}</td>
      </tr>
      <tr>
        <td colspan="">TOTAL</td>
        <td>${ventaInfo.total}</td>
      </tr>
    </tbody>
  </table>
  </div>
  <hr/>
  ${infoExtra?.comentarios ? `<p class="mb-1 text-center"  style="text-align: center;"><strong>Comentarios:&nbsp;</strong>${infoExtra.comentarios}</p>` : ""}
  ${infoExtra?.mensajeInformativo ? `<p class="mb-1 text-center"  style="text-align: center;"><strong>${infoExtra.mensajeInformativo}</strong></p>` : ""}
  ${infoExtra?.notaFinal ? `<p class="mb-1 text-center"  style="text-align: center;">${infoExtra.notaFinal}</p>` : ""}
  `
  return html
}