import moment from "moment"
import "moment/locale/es"

import { cartInfoDefault as cartInfoDefault_, getFechaTicket as getFechaTicket_, iniCartInfoBase } from "acciondigital-commons/cartCommonsUtils.js"

import { getStorageValue } from "./browserUtils.js"

moment.locale("es")

export const cartInfoDefault = cartInfoDefault_


export const getFechaTicket = getFechaTicket_

export const iniCartInfo = cartInfoDef => {
  return iniCartInfoBase(cartInfoDef, info =>
    getStorageValue(
      `currentCart_${info.sitio}${info.user_uuid ? "_" + info.user_uuid : ""}`,
      undefined
    )
  )
}
