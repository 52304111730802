import moment from "moment"
import axios from "axios"

import { getFechaCreacionObj } from "./commonUtils.js"

export const usuarios_area = "usuarios"
export const usuariosControl_area = "usuarios-control"

export const GET_USERS_FIREBASE_API = `https://us-central1-igpingroute.cloudfunctions.net/get_users_test`

export const getUsersFirebase_API = async (siteId, idToken) => {
  const response = await axios.get(
    `${GET_USERS_FIREBASE_API}?sitio=${siteId}&token=${idToken}`
  )

  const { userList, codigo, length } = response.data
  console.log("🚀 ~ file: useUsersAll.js:40 ~ useUsersAllRQ ~ response.data:", {
    userList: userList?.length,
    codigo,
    length,
  })
  if (codigo == 0) {
    const resultado = {}
    resultado.users = userList.reduce((obj, u) => {
      obj[u.uid] = u
      return obj
    }, {})
    resultado.length = length
    resultado.usersList = userList
    return resultado
  } else {
    return response.data
  }
}

export const saveUserInfoBase = async (
    firebase,
    refSitio,
    user,
    newDatos,
    fechaDate = new Date()
  ) => {
    console.debug(
      "🚀 ~ file: usuarios-commons.js:16 ~ useLogin ~ refSitio",
      refSitio,
      user?.uid,
      newDatos
    )
    if (!refSitio || !user?.uid) return

    const fechaCrea = getFechaCreacionObj(fechaDate)

    let usuario = {
      uid: user.uid,
    }

    let info = { uid: user.uid, ...fechaCrea }

    if (newDatos.info) {
      info = {
        ...newDatos.info,
        ...(newDatos.info.email ? { mail: newDatos.info.email } : {}),
        uid: user.uid,
        ...fechaCrea,
      }
      usuario = {
        ...(newDatos.info.email ? { email: newDatos.info.email } : {}),
        uid: user.uid,
      }
    } else if (newDatos.email) {
      info = {
        email: newDatos.email,
        mail: newDatos.email,
        uid: user.uid,
        ...fechaCrea,
      }
      usuario = { email: newDatos.email, uid: user.uid }
    }

    const dbRefUser = firebase
      .database()
      .ref(`/app/${refSitio}/${usuarios_area}/${user.uid}/`)
    await dbRefUser.update(usuario)

    const dbRefUserInfo = firebase
      .database()
      .ref(`/app/${refSitio}/${usuarios_area}/${user.uid}/info/`)
    await dbRefUserInfo.update(info)
  }

export const saveUserInfoFromUserBase = async (firebase, refSitio, user) => {
  if (!user?.email || !user?.metadata?.creationTime) {
    console.debug("🚀 ~ file: usuarios-commons.js:65 ~ handleUpdateInfo ~ :", user?.email, user?.metadata?.creationTime)
    return
  }
  const newDatos = {
    info: {
      email: user.email,
      ...(user.displayName ? { nombre: user.displayName } : {}),
      ...(user.phoneNumber
        ? { telefono: user.phoneNumber }
        : {}),
    },
  }
  console.debug("🚀 ~ file: usuarios-commons.js:77 ~ handleUpdateInfo ~ newDatos:", newDatos, user.metadata.creationTime)
  
  await saveUserInfoBase(firebase, refSitio, { uid: user.uid }, newDatos, moment(user.metadata.creationTime).toDate())
}