import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import "moment/locale/es"

import { getFechaActualizacionObj, numberFormat } from "./commonUtils"

moment.locale("es")

export const cartInfoDefault = {
  items: [],
  total: 0,
  cantArticulos: 0,
  cantLineas: 0,
  cartInfoExtra: {},
  // vigencia_dias: vigenciaDiasDefault,
  control: {estatus: "NUEVO", vigente:true},
}


export const getFechaTicket = fecha_int =>
  fecha_int
    ? moment(new Date(fecha_int))
        .format("DD[-]MMM[-]YY hh:mm A")
        .replace(".", "")
        .toUpperCase()
    : ""

export const iniCartInfoBase = (cartInfoDef, recuperaPedidoFun) => {
  const {
    nuevo = false,
    sitio,
    vigenciaDiasDefault,
    user_uuid,
    newItems,
    vigencia_dias,
    ...rest
  } = cartInfoDef
  // const cart_key = `currentCart_${sitio}${user_uuid ? "_" + user_uuid : ""}`

  if (!nuevo && recuperaPedidoFun) {
    // let currentCartStorageValue = recuperaPedidoFun(cart_key, undefined)
    let currentCartStorageValue = recuperaPedidoFun(cartInfoDef)
    if (currentCartStorageValue?.uuid) {
      console.debug(
        "CartContext iniCartInfo get currentCartStorageValue",
        currentCartStorageValue
      )
      return { ...currentCartStorageValue }
    }
  }

  const vigenciaDiasCalc = vigencia_dias??vigenciaDiasDefault

  const fecha_date = new Date()
  console.debug("🚀 ~ file: CartContext.js:134 ~ iniCartInfo cartNuevo ~ fecha_date:", fecha_date)
  let fecha_vigencia_date
  if(vigenciaDiasCalc >= 0){
    fecha_vigencia_date = new Date()
    fecha_vigencia_date.setDate(
      fecha_vigencia_date.getDate() + (vigenciaDiasCalc)
    )
  }

  const userInfo = {}

  if (user_uuid) {

    userInfo["user-info"]={}

    const info = { uid: user_uuid, "creador-uid": user_uuid}

    Object.entries(info).forEach(([key,value])=>{

      userInfo["user-info"][key]=value

      userInfo["user-"+key]=value
    })

  }

  return {
    ...rest,
    uuid: uuidv4(),
    items: newItems ?? [],
    fecha: moment(fecha_date).format(),
    fecha_int: fecha_date.getTime(),
    fecha_date,
    ...(fecha_vigencia_date
      ? {
          fecha_vigencia: moment(fecha_vigencia_date).format(),
          fecha_vigencia_int: fecha_vigencia_date.getTime(),
          fecha_vigencia_date,
        }
      : {}),
    vigencia_dias: vigenciaDiasCalc,
    ...userInfo,
  }
}

export const addChangeToCartBase = (
  codigo,
  nombre,
  precio,
  cantidad,
  esCambio,
  items,
  itemInfoExtra
) => {

  if(!codigo) return console.error("addChangeToCartBase: codigo no definido")

  const lineItem = items.find(element => element.codigo === codigo)
  const cant = cantidad ? cantidad : 1
  const precio_prod = precio ? precio : 0

  if (lineItem) {
    const newCant = lineItem.cantidad * (esCambio == true ? 0 : 1) + cant
    if (newCant <= 0) {
      const removeIndex = items.findIndex(item => item.codigo === codigo)
      items.splice(removeIndex, 1)
      // remove object
    } else {
      lineItem.cantidad = newCant
      lineItem.precio = precio_prod
      lineItem.importe = lineItem.cantidad * lineItem.precio
    }
  } else if (cant > 0) {
    const newLineItem = {
      codigo,
      descripcion: nombre ? nombre : codigo,
      cantidad: cant,
      precio: precio_prod,
      importe: cant * precio_prod,
      ...(itemInfoExtra ? itemInfoExtra : {}),
      ...(itemInfoExtra ? { itemInfoExtra: itemInfoExtra } : {}),
    }
    items.push(newLineItem)
  }
}

export const calcularPeso = (items) => {
  if(!items) return 0

  return items.reduce(
    (p, item) => p + (item.peso ? item.peso : 0) * item.cantidad,
    0
  )
}

export const calcularVolumen = (items) => {
  if(!items) return 0

  return items.reduce(
    (p, item) => p + (item.volumen ? item.volumen : 0) * item.cantidad,
    0
  )
}

export const calcularTotal = (items, descuento) => {
  if(!items) return 0

  const subtotal = items.reduce((tot, item) => tot + item.importe, 0)
  return descuento ? subtotal * (1 - descuento / 100) : subtotal
}

export const calcularCantArticulos = items => {
  if(!items) return 0

  return items.reduce(
    (tot, item) => tot + (item.cantidad ? item.cantidad * 1 : 0),
    0
  )
}
export const calcularCantLineas = items => {
  if(!items) return 0

  return items.length
}


export const getItemLineStr = item => {
  return getItemLineStrOpciones(item, false)
}

export const getItemLineStrOpciones = (item, ocultarPrecios = false) => {
  const precios = !ocultarPrecios
    ? `, (${numberFormat(item.precio)} / ${numberFormat(item.importe)})`
    : ""
  return `►${item.cantidad} x ${item.descripcion} [${item.codigo}]` + precios
}

export const getCartItemsStrOper = (items) => {
  
  let itemsArray = items.map(item => {
    return getItemLineStr(item)
  })
  return itemsArray
}

export const getCartStrOpcionesOper = (cartInfo, ocultarPrecios = false, ocultarTotal = false) => {
  const { items, total, cantArticulos } = cartInfo
  const itemsStr = items.reduce((str, item) => {
    return str + "\n" + getItemLineStrOpciones(item, ocultarPrecios)
  }, "")
  const totalStr = !ocultarTotal
    ? `\n*TOTAL APROXIMADO: ${numberFormat(total)}*`
    : ""

  return `${itemsStr} 
${totalStr}
_(${cantArticulos} artículo${cantArticulos == 1 ? "" : "s"})_`
}


export const getCartHtmlOper = (cartInfo, opciones={}) => {
  const {urlPedido=true} = opciones
  const {
    items,
    total,
    cantArticulos,
    fecha_vigencia_int,
    uuid,
    cartInfoExtra,
  } = cartInfo
  let html = `
  ${items.reduce((str, item) => {
    return str + `<p className="m-1">${getItemLineStr(item)}<p/>`
  }, "")}
  <br />
  <br />
  <strong>TOTAL APROXIMADO: ${numberFormat(total)}</strong>
  <br />
  <em>(${cantArticulos} artículo${cantArticulos === 1 ? "" : "s"})</em>
  <br />
  <br />
  ${
    fecha_vigencia_int
      ? `<p><small>Vigente al: ${fecha_vigencia_int}</small></p>`
      : ""
  }      
  ${
    cartInfoExtra?.siteUrl && urlPedido
      ? `<p><small>${cartInfoExtra?.siteUrl + "/cotizacion/" + uuid}</small></p>`
      : uuid
  }
  ${
    cartInfoExtra?.tienda || cartInfoExtra?.telefono || cartInfoExtra?.siteUrl
      ? `<div>
      <p> ++++++++++++ </p>
      <p className="mb-1">${cartInfoExtra?.tienda?.toLocaleUpperCase()}</p>
      <p className="mb-1"> ${cartInfoExtra?.telefono} </p>
      <p className="mb-1">${cartInfoExtra?.siteUrl}</p>
    </div>`
      : ""
  }
  `
  return html
}

export const preparaInfoCartCargaOper = (cartData, new_user_uid) => {
  const {
    key,
    uuid,
    items = [],
    // fecha_date: ff_date,
    fecha_int: f_int,
    vigencia_dias,
    // fecha_vigencia_date: f_vigencia_date,
    fecha_vigencia_int: f_vigencia_int,
    cartInfoExtra,
    control,
    "user-info": old_user_info,
    "user-uid": old_user_uid,
    ...restCardData
  } = cartData

  // let f_date
  let fecha_date
  let fecha_int
  let fecha
  let fecha_vigencia_date
  let fecha_vigencia_int
  let fecha_vigencia

  // if (ff_date) {
  //   f_date = new Date(ff_date)
  //   fecha_date = f_date
  //   fecha_int = f_date.getTime()
  //   fecha = moment(f_date).format()
  // } else 
  if (f_int) {
    fecha_int = f_int
    fecha_date = new Date(f_int)
    fecha = moment(fecha_date).format()
  }

  // if (f_vigencia_date) {
  //   fecha_vigencia_date = f_vigencia_date
  //   fecha_vigencia_int = f_vigencia_date.getTime()
  //   fecha_vigencia = moment(f_vigencia_date).format()
  // } else 
  if (f_vigencia_int) {
    fecha_vigencia_int = f_vigencia_int
    fecha_vigencia_date = new Date(f_vigencia_int)
    fecha_vigencia = moment(fecha_vigencia_date).format()
  } else if (vigencia_dias >= 0 && fecha_date) {
    fecha_vigencia_date = fecha_date
    fecha_vigencia_date.setDate(fecha_vigencia_date.getDate() + vigencia_dias)
    fecha_vigencia_int = fecha_vigencia_date.getTime()
    fecha_vigencia = moment(fecha_vigencia_date).format()
  }

  if (!fecha && fecha_vigencia) {
    fecha_date = fecha_vigencia_date
    fecha_int = fecha_vigencia_int
    fecha = fecha_vigencia
  }

  // const new_user_uid = user?.uid ?? ""

  const {uid, ...u_info} = old_user_info ?? {}

  const new_user_obj = {}

  if (new_user_uid) {
    u_info.uid = new_user_uid
  }

  if(Object.entries(u_info).length > 0) new_user_obj["user-info"]={}


  Object.entries(u_info).forEach(([key,value])=>{

    new_user_obj["user-info"][key]=value

    new_user_obj["user-"+key]=value
  })

  return {
    ...restCardData,
    uuid,
    items,
    ...(fecha ? { fecha_int, fecha_date, fecha } : {}),
    ...(vigencia_dias != null ? { vigencia_dias } : {}),
    ...(fecha_vigencia
      ? { fecha_vigencia_int, fecha_vigencia_date, fecha_vigencia }
      : {}),
    cartInfoExtra,
    control,
    ...new_user_obj,
  }
}

export const crearCalculoCartOper = (reglas) => {
return (cartInfoNew, itemsNew, newCartInfoExtra = {}, newControl = {}) => {


  /////////TODO XXX CAMBIO TEMPORAL ///////////////// <----
  // const controlTmp = {
  //   ...(!cartInfoNew.control && cartInfoNew.estatus
  //     ? { estatus: cartInfoNew.estatus }
  //     : {}),
  //   ...(!cartInfoNew.control && cartInfoNew.vigente
  //     ? { vigente: cartInfoNew.vigente }
  //     : {}),
  // }
  // const { cartInfoExtra = {}, control = {controlTmp}, ...rest} = cartInfoNew
  //////////////////////////---->


  const { cartInfoExtra = {}, control = {}, ...rest} = cartInfoNew

  const { cerrado } = control
  if(cerrado) return cartInfoNew

  if(reglas?.[0]){
    for (let idx = 0; idx < reglas.length; idx++) {
      const reglaFn = reglas[idx];
      reglaFn(cartInfoNew, itemsNew, newCartInfoExtra, newControl)
    }
  }

  const {cerrado: newCerrado, estatus: newEstatus, descuento} = newControl

  const controlCal = {...control, ...newControl, descuento: descuento??null}
  console.debug("🚀 ~ file: cartCommonsUtils.js:356 ~ calcularCartOper ~ controlCal:", controlCal)

  controlCal.estatus = newEstatus??"MODIFICADO"

  const now = new Date().getTime()
  controlCal.vigente = !rest.fecha_vigencia_int || rest.fecha_vigencia_int >= now
  if(!controlCal.vigente) {
    controlCal.estatus = "NO VIGENTE"
    controlCal.cerrado = true
  }

  if(newCerrado) {
    controlCal.estatus = newEstatus??"CERRADO"
    controlCal.cerrado = true
    controlCal.vigente = false
  }

  const objFecha = getFechaActualizacionObj()

  if(controlCal.cerrado){
    return {
      ...cartInfoNew,
      cartInfoExtra: { ...cartInfoExtra, ...newCartInfoExtra },
      ...cartInfoExtra,
      ...newCartInfoExtra,
      control: controlCal,
      ...controlCal,
      ...objFecha,
    }
  }

  return {
    ...rest,
    items: itemsNew,
    subtotal: calcularTotal(itemsNew),
    total: calcularTotal(itemsNew, controlCal.descuento),
    pesoTotal: calcularPeso(itemsNew),
    volumenTotal: calcularVolumen(itemsNew),
    cantArticulos: calcularCantArticulos(itemsNew),
    cantLineas: calcularCantLineas(itemsNew),
    cartInfoExtra: { ...cartInfoExtra, ...newCartInfoExtra },
    ...cartInfoExtra,
    ...newCartInfoExtra,
    control: controlCal,
    ...controlCal,
    ...objFecha,
  }
}
}

export const calcularCartOper = crearCalculoCartOper()

export const calcularCartFromDBOper = (pedidoInfoDB = {items:[]}) => calcularCartOper(preparaInfoCartCargaOper(pedidoInfoDB), pedidoInfoDB.items)

export const getTextoWhatsappOper = ({
  telefono,
  cartInfo,
  enviarLink,
  siteUrl = "",
  mensajeHeader,
  mensajeSaludo = "¡Hola!",
  mensajeDespedida = "Gracias",
  mensajeFooter,
  comentarios,
  basePath = "cotizacion",
  opciones = {},
}) => {
  const { ocultarPrecios, ocultarTotal, mostrarComentarios=false } = opciones
  const { uuid: cartId, fecha_int, fecha_vigencia_int } = cartInfo
  let fecha = getFechaTicket(fecha_int)
  let fecha_vigencia = getFechaTicket(fecha_vigencia_int)

  const number = telefono ? `${telefono.split(" ").join("")}` : ""
  let texto =
    "https://wa.me" +
    (number ? "/521" + number : "") +
    "?text=%20" +
    encodeURIComponent(
      (mensajeHeader
        ? "\n" + mensajeHeader + "\n\n++++++++++++\n\n"
        : "") +
        `${mensajeSaludo}\n` +
        `${fecha}\n` +
        (enviarLink
          ? `\n${siteUrl}/${basePath}/${cartId}\n`
          : "") +
        getCartStrOpcionesOper(cartInfo, ocultarPrecios, ocultarTotal) +
        (fecha_vigencia ? `\n\nVigente al: ${fecha_vigencia}` : "") +
        `\n\n${mensajeDespedida}` +
        (mensajeFooter
          ? "\n\n++++++++++++\n\n" + mensajeFooter + "\n"
          : "") +
        (mostrarComentarios && comentarios
          ? "\n\n------------\n\n" + comentarios + "\n"
          : "")
    )
  return texto
}


export const getMensajeWhatsappGenerico = (telefono, mensaje) => {
  const number = telefono ? `${telefono.split(" ").join("")}` : ""
  let texto =
    "https://wa.me" +
    (number ? "/521" + number : "") +
    "?text=%20" +
    encodeURIComponent(mensaje)
  return texto
}