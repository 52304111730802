import { useState } from "react"

import {
  getCartStrOpcionesOper,
  getCartHtmlOper,
  getCartItemsStrOper,
} from "acciondigital-commons/cartCommonsUtils"
import { ACTIONS } from "acciondigital-commons/cartReducer"

const useCartOpers = (
  cartInfoState,
  dispatch,
  user,
  preventSaveFirebaseRef,
  opciones = {}
) => {

  const { sitio, vigenciaDiasDefault } = opciones

  const [showModal, setShowModal] = useState(false)

  const [mensaje, setMensaje] = useState({})

  /*
      preventSaveFirebase = false significa que se HABILITA a guardar en firebase al cargar
      preventSaveFirebase = true significa que se va a EVITAR guardar en firebase al cargar
     */
  const setCartInfoExtra = (cartInfoExtra, preventSaveFirebase = false) => {
    preventSaveFirebaseRef.current = preventSaveFirebase

    return dispatch({
      type: ACTIONS.SET_CART_INFOEXTRA,
      payload: { cartInfoExtra },
    })
  }

  const setCartControl = (
    control,
    cartInfoExtra,
    preventSaveFirebase = false
  ) => {
    preventSaveFirebaseRef.current = preventSaveFirebase

    const { estatus, descuento } = control

    return dispatch({
      type: ACTIONS.SET_CART_CONTROL,
      payload: { cartInfoExtra, control: { estatus, descuento } },
    })
  }

  const cerrarCart = (estatus, cartInfoExtra) => {
    preventSaveFirebaseRef.current = false

    return dispatch({
      type: ACTIONS.SET_CART_CONTROL,
      payload: {
        cartInfoExtra,
        control: { estatus: estatus ?? "CERRADO", cerrado: true },
      },
    })
  }

  const masUno = codigo => {
    return dispatch({
      type: ACTIONS.MAS_UNO,
      payload: { codigo },
    })
  }

  const menosUno = codigo => {
    return dispatch({
      type: ACTIONS.MENOS_UNO,
      payload: { codigo },
    })
  }

  const eliminarItemLine = codigo => {
    return dispatch({
      type: ACTIONS.ELIMINAR_ITEM_LINE,
      payload: { codigo },
    })
  }

  // const addChangeToCart = (codigo, nombre, precio, cantidad, esCambio) => {
  //   // const { items } = cartInfo
  //   // addChangeToCartBase(codigo, nombre, precio, cantidad, esCambio, items)
  //   // // `${codigo} - ${nombre?nombre:codigo} - ${cantidad?cantidad:1}- ${precio?precio:1}`
  //   // setCartInfo(prev => ({
  //   //   ...prev,
  //   //   items: items,
  //   //   total: calcularTotal(items),
  //   //   cantArticulos: calcularCantArticulos(items),
  //   // }))
  // }

  const addToCart = (
    codigo,
    nombre,
    precio,
    cantidad,
    itemInfoExtra,
    cartInfoExtra,
    options
  ) => {
    const {prodId} = options ?? {}
    return dispatch({
      type: ACTIONS.ADD_TO_CART,
      payload: {
        codigo,
        nombre,
        precio,
        cantidad,
        itemInfoExtra,
        cartInfoExtra,
        prodId
      },
    })
  }

  const changeItemLine = (
    codigo,
    nombre,
    precio,
    cantidad,
    itemInfoExtra,
    cartInfoExtra,
    options
  ) => {
    const {prodId, cbFn} = options ?? {}
    return dispatch({
      type: ACTIONS.CHANGE_ITEM_LINE,
      payload: {
        codigo,
        nombre,
        precio,
        cantidad,
        itemInfoExtra,
        cartInfoExtra,
        prodId
      },
      cbFn,
    })
  }

  const clearCart = () => {
    return dispatch({
      type: ACTIONS.CLEAR_CART,
      payload: {},
    })
  }

  const newCart = (cartInfoExtra, vigencia_dias) => {
    preventSaveFirebaseRef.current = true

    const user_uuid = user?.uid ? user.uid : ""
    return dispatch({
      type: ACTIONS.NUEVO_CART,
      payload: {
        sitio,
        vigenciaDiasDefault,
        user_uuid,
        cartInfoExtra,
        vigencia_dias,
      },
    })
  }

  const cloneCart = (items, cartInfoExtra, vigencia_dias) => {
    const user_uuid = user?.uid ? user.uid : ""
    return dispatch({
      type: ACTIONS.CLONE_CART,
      payload: {
        sitio,
        vigenciaDiasDefault,
        user_uuid,
        newItems: items,
        newCartInfoExtra: cartInfoExtra,
        new_vigencia_dias: vigencia_dias,
      },
    })
  }

  const cargarCartDataFromDB = (
    pedidoInfoDB,
    options = {},
    preventSaveFirebase = true
  ) => {
    if (pedidoInfoDB?.uuid)
      return cargarCartData(pedidoInfoDB, preventSaveFirebase, options)
  }

  /*
      preventSaveFirebase = true significa que se va a EVITAR guardar en firebase al cargar
      preventSaveFirebase = false significa que se HABILITA a guardar en firebase al cargar
     */
  const cargarCartData = (
    cartDataPayload,
    preventSaveFirebase = true,
    options
  ) => {
    preventSaveFirebaseRef.current = preventSaveFirebase

    // const payload = preparaInfoCartCargaOper(cartData, user?.uid ?? "")

    cartDataPayload.new_user_uid = user?.uid ?? ""
    if (options) cartDataPayload.options = options

    return dispatch(
      {
        type: ACTIONS.CARGAR_CART,
        payload: cartDataPayload,
      },
      cartDataPayload.uuid
    )
  }

  /* const cargarCart = (
      uuid,
      items = [],
      total,
      cantArticulos,
      fecha_vigencia,
      fecha_vigencia_int,
      cartInfoExtra,
      preventSaveFirebase = true,
    ) => {
      preventSaveFirebaseRef.current = preventSaveFirebase
      const user_uuid = user?.uid ? user.uid : ""
      const userInfo = {}
      if (user_uuid) {
        userInfo["user-info"] = { uid: user_uuid }
        userInfo["user-uid"] = user_uuid
      }
      dispatch({
        type: ACTIONS.CARGAR_CART,
        payload: {
          uuid,
          items,
          fecha_vigencia,
          fecha_vigencia_int,
          cartInfoExtra,
          ...userInfo,
        },
      })
    } */

  const cartSize = () => {
    return cartInfoState.cantArticulos
  }

  const getCartItems = () => {
    const { items } = cartInfoState
    return items
  }

  const getCartItemsStr = () => {
    const { items } = cartInfoState
    return getCartItemsStrOper(items)
  }

  const getCartStr = () => {
    return getCartStrOpciones(false, false)
  }

  const getCartStrOpciones = (ocultarPrecios = false, ocultarTotal = false) => {
    return getCartStrOpcionesOper(cartInfoState, ocultarPrecios, ocultarTotal)
  }

  const getCartHtml = (opciones = {}) => {
    return getCartHtmlOper(cartInfoState, opciones)
  }

  return {
    showModal,
    setShowModal,
    mensaje,
    setMensaje,
    getCartItems,
    getItemsStr: getCartItemsStr,
    addToCart: addToCart,
    clearCart: clearCart,
    newCart: newCart,
    cloneCart: cloneCart,
    // cargarCart: cargarCart,
    cargarCartData,
    cargarCartDataFromDB,
    changeItemLine: changeItemLine,
    eliminarItemLine: eliminarItemLine,
    cartSize: cartSize,
    getCartStr: getCartStr,
    getCartStrOpciones: getCartStrOpciones,
    getCartHtml: getCartHtml,
    masUno: masUno,
    menosUno: menosUno,
    setCartInfoExtra,
    setCartControl,
    cerrarCart,
  }
}

export default useCartOpers