
// import { v4 as uuidv4 } from "uuid"
// import moment from "moment"

import { cartInfoDefault, addChangeToCartBase, preparaInfoCartCargaOper, crearCalculoCartOper, } from "./cartCommonsUtils"
  

export const ACTIONS = {
    ADD_TO_CART: "addToCart",
    CHANGE_ITEM_LINE: "changeItemLine",
    MAS_UNO: "masUno",
    MENOS_UNO: "menosUno",
    ELIMINAR_ITEM_LINE: "eliminarItemLine",
    CARGAR_NUEVO: "cargarNuevo",
    GET_CART: "getCart",
    NUEVO_CART: "newCart",
    CLEAR_CART: "clearCart",
    CLONE_CART: "cloneCart",
    CARGAR_CART: "cargarCart",
    SET_CART_INFOEXTRA: "setCartInfoExtra",
    SET_CART_CONTROL: "setCartControl",
  }

  
  
  export const getReducer = (iniCartInfo, reglas) =>{
    const calcularCartOper = crearCalculoCartOper(reglas)
    return  function (state, action) {
    switch (action.type) {
      case ACTIONS.ADD_TO_CART: {
        // console.debug("CartContext ADD_TO_CART payload", action.payload)
        const {
          codigo,
          nombre,
          precio,
          cantidad,
          itemInfoExtra,
          cartInfoExtra,
        } = action.payload
        // const { items } = state
        const items = JSON.parse(JSON.stringify(state.items))
        addChangeToCartBase(
          codigo,
          nombre,
          precio,
          cantidad,
          false,
          items,
          itemInfoExtra
        )
        return calcularCartOper(state, items, cartInfoExtra)
      }
      case ACTIONS.CHANGE_ITEM_LINE: {
        const {
          codigo,
          nombre,
          precio,
          cantidad,
          itemInfoExtra,
          cartInfoExtra,
        } = action.payload
        const { items } = state
        addChangeToCartBase(
          codigo,
          nombre,
          precio,
          cantidad,
          true,
          items,
          itemInfoExtra
        )
        return calcularCartOper(state, items, cartInfoExtra)
      }
      case ACTIONS.MAS_UNO: {
        const { codigo } = action.payload
        // const { items } = state
        const items = JSON.parse(JSON.stringify(state.items))
        const lineItem = items.find(element => element.codigo === codigo)
        if (lineItem)
          addChangeToCartBase(
            codigo,
            lineItem.descripcion,
            lineItem.precio,
            1,
            false,
            items
          )
        return calcularCartOper(state, items)
      }
      case ACTIONS.MENOS_UNO: {
        const { codigo } = action.payload
        // const { items } = state
        const items = JSON.parse(JSON.stringify(state.items))
        const lineItem = items.find(element => element.codigo === codigo)
        if (lineItem)
          addChangeToCartBase(
            codigo,
            lineItem.descripcion,
            lineItem.precio,
            -1,
            false,
            items
          )
        return calcularCartOper(state, items)
      }
      case ACTIONS.ELIMINAR_ITEM_LINE:
        {
          const { codigo } = action.payload
          // const { items } = state
          const items = JSON.parse(JSON.stringify(state.items))
          const lineItem = items.find(element => element.codigo === codigo)
          if (lineItem) {
            const removeIndex = items.findIndex(item => item.codigo === codigo)
            items.splice(removeIndex, 1)

          }
          return calcularCartOper(state, items)
        }
        break
      case ACTIONS.CARGAR_NUEVO: {
        const { user_uuid, sitio, vigenciaDiasDefault } = action.payload
        const { "user-uid": user_uuid_prev = "" } = state
        console.debug(
          "🚀 ~ file: CartContext.js:298 ~ reducer ~ ACTIONS.CARGAR_NUEVO:\n",
          "user_uuid === user_uuid_prev",
          user_uuid === user_uuid_prev,
          "user_uuid",
          user_uuid,
          "user_uuid_prev",
          user_uuid_prev
        )

        if (user_uuid === user_uuid_prev) return state

        const newState = iniCartInfo({
          ...cartInfoDefault,
          sitio,
          vigenciaDiasDefault,
          user_uuid,
        })

        return calcularCartOper(
          newState,
          newState.items,
          null,
          newState.control
        )
      }
      case ACTIONS.NUEVO_CART: {
        const {
          user_uuid,
          sitio,
          vigenciaDiasDefault,
          cartInfoExtra,
          vigencia_dias,
        } = action.payload

        const newState = iniCartInfo({
          ...cartInfoDefault,
          sitio,
          vigenciaDiasDefault,
          user_uuid,
          nuevo: true,
          ...(vigencia_dias != null ? { vigencia_dias } : {}),
        })

        return calcularCartOper(
          newState,
          newState.items,
          cartInfoExtra,
          newState.control
        )
      }
      case ACTIONS.CLEAR_CART: {
        return calcularCartOper(state, [])
      }
      case ACTIONS.CLONE_CART: {
        const {
          user_uuid,
          sitio,
          vigenciaDiasDefault,
          newItems,
          newCartInfoExtra,
          new_vigencia_dias,
        } = action.payload
        const {
          items: prevItems,
          cartInfoExtra: prevCartInfoExtra,
          vigencia_dias: prev_vigencia_dias,
        } = state

        const newState = iniCartInfo({
          ...cartInfoDefault,
          sitio,
          vigenciaDiasDefault,
          user_uuid,
          newItems: newItems ?? prevItems,
          cartInfoExtra: prevCartInfoExtra,
          nuevo: true,
          vigencia_dias: new_vigencia_dias ?? prev_vigencia_dias,
        })

        return calcularCartOper(newState, newState.items, newCartInfoExtra, {
          estatus: "CLONADO",
        })
      }
      case ACTIONS.CARGAR_CART: {
        const {
          // newEstatus,
          new_user_uid,
          options = {},
          ...cartData
        } = action.payload

        const { cartInfoExtra: cartInfoExtraNew, newEstatus, ...restOptions } = options

        const payload = preparaInfoCartCargaOper(
          {
            ...cartData,
            // cartInfoExtra: {
            //   ...(cartData.cartInfoExtra ? cartData.cartInfoExtra : {}),
            //   ...cartInfoExtraNew,
            // },
            ...restOptions,
          },
          new_user_uid
        )


        const { items } = payload

        return calcularCartOper(payload, items, cartInfoExtraNew, { estatus: newEstatus??"CARGADO" })
      }
      case ACTIONS.SET_CART_INFOEXTRA: {
        // const { cartInfoExtra: newCartInfoExtra = {} } = action.payload
        // console.debug("🚀 ~ file: CartContext.js:351 ~ reducer ~ newCartInfoExtra:", newCartInfoExtra)
        // const { cartInfoExtra = {} } = state

        // return {
        //   ...state,
        //   ...cartInfoExtra,
        //   ...newCartInfoExtra,
        //   cartInfoExtra: { ...cartInfoExtra, ...newCartInfoExtra },
        // }

        const { cartInfoExtra = {} } = action.payload
        const { items } = state
        return calcularCartOper(state, items, cartInfoExtra)
      }
      case ACTIONS.SET_CART_CONTROL: {
        const { control = {}, cartInfoExtra } = action.payload
        const { items } = state
        return calcularCartOper(state, items, cartInfoExtra, control)
      }

      default:
        throw new Error()
    }
  }
  }