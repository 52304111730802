  
  import { getAttributes, getFechaActualizacionObj } from "./commonUtils.js"
  import {
    addItemEntityBase,
    deleteItemEntityBase,
    getDataByFullPathBase,
    getItemEntityBase,
    getItemsAllEntityBase,
    updateItemEntityBase,
  } from "./firebaseBaseDataUtils.js"

export const pedidos_entity = "pedidos"
export const pedidos_area = "user-data"
// export const pedidos_usuariosEntity = "usuarios-entity"
// export const pedidos_entityUsuarios = "entity-usuarios"
export const pedidos_usuariosEntity = "usuarios_user-data"
export const pedidos_entityUsuarios = "user-data_usuarios"


const getUserPedidoInfo = pedidoInfo => {
    const info = getAttributes(
      pedidoInfo,
      [
        "uuid",
        "total",
        "cantArticulos",
        "fechaActualizacionInt",
        "fecha_int",
        "vigencia_dias",
      ],
      false,
      false
    )
  
    if (pedidoInfo["user-creador-uid"])
      info["user-creador-uid"] = pedidoInfo["user-creador-uid"]
  
    return info
  }
  
  export const savePedidoUsuarioBase = async (firebase, sitio, uuid, user_uuid, cartInfo) => {
    if (user_uuid) {
      await addItemEntityBase(
        firebase,
        sitio,
        pedidos_entity,
        pedidos_entityUsuarios,
        `${uuid}/${user_uuid}`,
        {
          acceso: true,
        }
      )
    }
  
    const vals = await getItemsAllEntityBase(
      firebase,
      sitio,
      pedidos_entity + "/" + uuid,
      pedidos_entityUsuarios,
      { includeKey: true }
    )
    console.debug("🚀 ~ file: pedidos.js:42 ~ savePedidoUsuario ~ vals:", vals)
  
    const cartInfoUsu = getUserPedidoInfo(cartInfo)
  
    const updates = {}
    if (vals?.[0]) {
      vals.forEach(v => {
        updates[
          `/app/${sitio}/${pedidos_usuariosEntity}/${pedidos_entity}/${v.key}/${uuid}/`
        ] = {
          ...cartInfoUsu,
          "user-uid": v.key + "",
        }
      })
      console.debug(
        "🚀 ~ file: pedidos.js:59 ~ savePedidoUsuario ~ updates:",
        updates
      )
      firebase.database().ref().update(updates)
    }
  }
  
  export const guardarPedidoBase = async (firebase, sitio, uuid, user_uuid, cartInfo) => {
    const refBase = `/app/${sitio}/${pedidos_area}/${pedidos_entity}/`
  
    if (!firebase || !sitio || !uuid || !cartInfo?.uuid) return false
  
    const dbRef = firebase.database().ref(refBase + uuid)
    await dbRef.set(cartInfo)
  
    await savePedidoUsuarioBase(firebase, sitio, uuid, user_uuid, cartInfo)
  
    //   /usuarios-entity user/ info {uid,fechaAct, total}
  
    // /user-data/pedidos/ user=true
  
    return true
  }


  /*
    actualiza los registros de usuarios-entity para todos los pedidos proporcionados
  */
    export const fixPedidos_CrearRegistrosUsuariosBase = async (firebase, siteId, pedidosLst) => {
      let count = 0
      for (let index = 0; index < pedidosLst.length; index++) {
        const p = pedidosLst[index]
        const {
          uuid,
          "user-uid": user_uid,
          "user-creador-uid": user_creador_uid,
        } = p

        if ((user_uid || user_creador_uid) && !pedidosUsus?.[uuid]) {
          await delay(700)
          await savePedidoUsuarioBase(firebase, siteId, uuid, user_uid, p)
          if (user_uid != user_creador_uid)
            await savePedidoUsuarioBase(
              firebase,
              siteId,
              uuid,
              user_creador_uid,
              p
            )
          count++
        }

        console.debug(
          "🚀 ~ file: pedidos-commons.js:124 ~ fixUsuarios ~    index:",
          index,
          count,
          uuid,
          user_uid
        )
        // if (count == 25) break
        // if (count == 50) break
        if (count == 100) break
      }
    }

    export const getPedidoBase = async (firebase, siteId, id) => {
      try {
        return await getItemEntityBase(
          firebase,
          siteId,
          pedidos_entity,
          pedidos_area,
          id,
          {
            includeKey: true,
          }
        )
      } catch (error) {
        console.error(error)
      }
    }

    export const prepararEliminarPedidoBase = async (firebase, siteId, id) => {

      const pedido = await getPedidoBase(firebase, siteId, id);
    
      if(!pedido) throw new Error(`No existe un pedido con el id [${id}]`)
    
      const fechaUpdObj = getFechaActualizacionObj()
    
      const updData = {
        DELETE: "DELETE",
        ...fechaUpdObj,
      }
    
      return await updateItemEntityBase(firebase, siteId, pedidos_entity, pedidos_area, id, updData, {
        includeKey: true,
      });
    
    }

    export const cancelarEliminarPedidoBase = async (firebase, siteId, id) => {

      const pedido = await getPedidoBase(firebase, siteId, id);
    
      if(!pedido) throw new Error(`No existe un pedido con el id [${id}]`)
    
      await deleteItemEntityBase(
        firebase,
        siteId,
        pedidos_entity,
        pedidos_area,
        id + "/DELETE"
      )
    }

    

export const confirmarEliminarPedidoBase = async (firebase, siteId, id) => {

  // const pedido_path = `/app/${siteId}/${pedidos_area}/${pedidos_entity}/${id}/`
  // const pedido = await getDataByFullPathBase(firebase, pedido_path)
  const pedido = await getPedidoBase(firebase, siteId, id);

  if (!pedido) throw new Error(`No existe un pedido con el id [${id}]`)

  if (pedido.DELETE != "DELETE")
    throw new Error(`No se puede eliminar el pedido [${id}]`)

  // const pedido_usuarios_path = `/app/${siteId}/${pedidos_entityUsuarios}/${pedidos_entity}/${id}/`

  // const info = await getDataByFullPathBase(firebase, pedido_usuarios_path)
  const info = await getItemsAllEntityBase(firebase, siteId, pedidos_entity+"/"+id, pedidos_entityUsuarios, { includeKey: true })
  console.debug(
    "🚀 ~ file: pedidos-commons.js:208 ~ confirmarEliminarPedidoBase ~ info:",
    { info: Object.keys(info) }
  )

  // const updates = { [pedido_path]: null, [pedido_usuarios_path]: null }
  if (info) {
    // Object.entries(info).forEach(([key, val]) => 
    for(let idx=0; idx < info.length; idx++) {
      const val = info[idx];
      const key = val.key;
      if (val.acceso){
        console.log(`await deleteItemEntityBase(firebase,`,siteId,pedidos_entity,pedidos_usuariosEntity,key + "/" + id)
        await deleteItemEntityBase(firebase,siteId,pedidos_entity,pedidos_usuariosEntity,key + "/" + id)

        

        console.log("await deleteItemEntityBase(firebase", siteId, pedidos_entity, pedidos_entityUsuarios, id + "/" + key)
        await deleteItemEntityBase(firebase, siteId, pedidos_entity, pedidos_entityUsuarios, id+ "/" + key)
      }
    //     updates[
    //       `/app/${siteId}/${pedidos_usuariosEntity}/${pedidos_entity}/${key}/${id}/`
    //     ] = null
    }
    // console.debug(
    //   "🚀 ~ file: pedidos.js:59 ~ savePedidoUsuario ~ updates:",
    //   updates
    // )
      // await firebase.database().ref().update(updates)
  }

  //TODO BORRAR TODOS LOS REGISTROS DE USUARIOS RELACIONADOS CON EL AFILIADO
  
  console.log("await deleteItemEntityBase(firebase", siteId, pedidos_entity, pedidos_area, id)
  await deleteItemEntityBase(firebase, siteId, pedidos_entity, pedidos_area, id)
}

export const confirmarEliminarPedidoBase_ = async (firebase, siteId, id) => {

  const pedido_path = `/app/${siteId}/${pedidos_area}/${pedidos_entity}/${id}/`
  const pedido = await getDataByFullPathBase(firebase, pedido_path)

  if (!pedido) throw new Error(`No existe un pedido con el id [${id}]`)

  if (pedido.DELETE != "DELETE")
    throw new Error(`No se puede eliminar el pedido [${id}]`)

  const pedido_usuarios_path = `/app/${siteId}/${pedidos_entityUsuarios}/${pedidos_entity}/${id}/`

  const info = await getDataByFullPathBase(firebase, pedido_usuarios_path)
  console.debug(
    "🚀 ~ file: pedidos-commons.js:208 ~ confirmarEliminarPedidoBase ~ info:",
    { info: Object.keys(info) }
  )

  const updates = { [pedido_path]: null, [pedido_usuarios_path]: null }
  if (info) {
    Object.entries(info).forEach(([key, val]) => {
      if (val.acceso)
        updates[
          `/app/${siteId}/${pedidos_usuariosEntity}/${pedidos_entity}/${key}/${id}/`
        ] = null
    })
    console.debug(
      "🚀 ~ file: pedidos.js:59 ~ savePedidoUsuario ~ updates:",
      updates
    )
      await firebase.database().ref().update(updates)
  }

  //TODO BORRAR TODOS LOS REGISTROS DE USUARIOS RELACIONADOS CON EL AFILIADO

  console.log(
    "await deleteItemEntityBase(firebase, siteId, afiliados_entity, afiliados_area, id)"
  )
}
    