import firebase from "acciondigital-basic-theme/src/utils/get-firebase-instance"
import {
  getItemsAllEntityBase,
  getItemEntityBase,
  deleteItemEntityBase,
  updateItemEntityBase,
  addItemEntityBase,
  getUserInfoFromTokenBase,
  getUserFromEmailBase,
  getUserBase,
} from "acciondigital-commons/firebaseBaseDataUtils"



export const getUserInfoFromToken = async token =>
  await getUserInfoFromTokenBase(firebase, token)

export const getUserFromEmail = async email =>
  await getUserFromEmailBase(firebase, email)

export const getUser = async uid => await getUserBase(firebase, uid)

export const getItemsAllEntity = async (siteId, entity, area, options = {}) =>
  await getItemsAllEntityBase(firebase, siteId, entity, area, options)

export const getItemEntity = async (siteId, entity, area, key, options = {}) =>
  await getItemEntityBase(firebase, siteId, entity, area, key, options)

export const deleteItemEntity = async (siteId, entity, area, key) =>
  await deleteItemEntityBase(firebase, siteId, entity, area, key)

export const updateItemEntity = async (
  site,
  entity,
  area,
  key,
  datos,
  options = {}
) =>
  await updateItemEntityBase(firebase, site, entity, area, key, datos, options)

export const addItemEntity = async (
  site,
  entity,
  area,
  key,
  datos,
  options = {}
) => await addItemEntityBase(firebase, site, entity, area, key, datos, options)

/* 




exports.getItemsByParam = async (
  site,
  entity,
  param,
  value,
  area = "page-data"
) => {
  //   console.debug({ datos });

  const dbRef = admin.database().ref(`/app/${site}/${area}`)

  try {
    const data = await dbRef
      .child(entity)
      .orderByChild(param)
      .equalTo(value)
      .once("value")
    const data_val = data.val()
    // console.debug("getItems", data_val);
    console.debug(
      "getItemsByParam",
      Object.values(data_val)?.length,
      Object.values(data_val)?.[0]
        ? Object.entries(Object.values(data_val)[0]).reduce(
            (obj, [key, value], idx) => {
              if (idx < 5) obj[key] = value
              return obj
            },
            {}
          )
        : {}
    )

    // return Object.values(data_val)
    return {
      codigo: 0,
      mensaje: "Consulta exitosa.",
      items: Object.values(data_val),
    }
  } catch (error) {
    console.error(error.message)
    return {
      codigo: -1,
      mensaje: "ERROR: " + error.message,
      items: [],
    }
  }

  //   return callback({ test: "test" });
}
 */
