
//alexluong/gatsby-packages
//gatsby-plugin-use-query-params jumps to top of the page when a query param is set #33
//https://github.com/alexluong/gatsby-packages/issues/33
export const shouldUpdateScrollFn = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
    if (location.search.includes("q=")) {
      return false
    }
    const currentPosition = getSavedScrollPosition(location)
    return currentPosition || [0, 0]
  }